::-webkit-scrollbar {
  width: 5px !important;
  height: nb-theme(scrollbar-width) !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px !important;
}

::-webkit-scrollbar-track {
  background: nb-theme(scrollbar-background-color);
}
