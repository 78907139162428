@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';
@import '@nebular/auth/styles/globals';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';

@import './layout';
@import './overrides';
@import './ng-select';

@import 'intl-tel-input/build/css/intlTelInput.css';

// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();

  @include nb-overrides();

  .table-icon {
    height: 1rem;
    cursor: pointer;
    color: nb-theme(color-primary-default);
  }

  .table-icon.disabled {
    cursor: not-allowed;
    color: nb-theme(color-primary-disabled);
  }
}

@media (max-width: 1620px) {
  html {
    font-size: 13px !important;
  }
}

@media (max-width: 1400px) {
  html {
    font-size: 11px !important;
  }
}

// Customize nebular Checkboxes in Configuration module
.nb-theme-default nb-checkbox.status-basic .custom-checkbox.checked {
  background-color: #0095d3;
  border-color: #0095d3;
  width: 1.56rem;
  height: 1.56rem;
}

.nb-theme-default nb-checkbox.status-basic .custom-checkbox {
  background-color: rgba(143, 155, 179, 0.08);
  border-color: #8f9bb3;
  width: 1.56rem;
  height: 1.56rem;
}

.nb-theme-default nb-checkbox.status-basic .native-input:enabled:focus + .custom-checkbox.indeterminate,
.nb-theme-default nb-checkbox.status-basic .native-input:enabled:focus + .custom-checkbox.checked {
  background-color: #0095d3;
  border-color: #0095d3;
}
.nb-theme-default nb-toastr-container {
  position: absolute !important;
  left: 50%;
  margin-left: -12.5rem;

  nb-toast .message {
    font-size: 0.9rem;
  }
}

html {
  font-size: 14px;
}

body {
  min-width: 0 !important;
}

button[NbButton].size-medium {
  margin: 0 0.7rem;
  height: 2rem;
  line-height: 1rem;
}

/** search icon positioned in a textbox **/
.search {
  position: relative;
  .fa-search {
    position: absolute;
    right: 0.5rem;
    top: 0;
    font-size: 1rem;
    color: #0095d3;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.no-title-toast .title.subtitle {
  display: none;
}

.flex-separator {
  flex: 1;
}

.error.labeled-control .label,
.error .labeled-control .label {
  color: red;
}
.labeled-control {
  position: relative;

  .label-separate,
  .label {
  }
  .label {
    position: absolute;
    z-index: 1;
    top: -0.7rem;
    left: 10px;
    font-size: 0.7rem;
    background: #fff;
    color: black;
    font-weight: normal;
    padding: 0 5px 0 5px;
  }
  .label-separate {
    position: unset;
    z-index: unset;
    top: unset;
    left: unset;
    font-size: unset;
    padding: 0;
    margin-bottom: 0.5rem;
  }
  .sub-label {
    //padding: 0 5px 0 5px;
    margin-top: -0.5rem;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
  }

  &.required .label::after {
    content: ' *';
    color: red;
  }

  .error {
    color: red;
    font-size: 0.7rem;
  }

  &.inline {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    .label {
      position: unset;
      font-size: 1rem;
      background: none;
    }
  }
}

button.cursor-pointer {
  cursor: pointer !important;
}

.verification-management-menu-top {
  margin-bottom: 1rem;
}
.verification-management-menu li a,
.verification-management-menu-top li a {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

.nb-theme-default {
  h5,
  h6 {
    font-weight: 700;
  }
  h5 {
    font-size: 1.375rem;
    line-height: 2rem;
  }
  h6 {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
}
nb-auth-block {
  max-width: inherit !important;
}

.above-label-input {
  position: absolute;
  top: -15px;
  left: 10px;
  font-size: 10px;
  background: white;
}

@import 'scroll-bars';

nb-card-body.no-padding {
  padding: unset !important;
}

.no-background {
  background: #edf1f7;
}

span.highlight {
  background-color: yellow;
}
