@import './themes';

@mixin nb-overrides() {
  nb-select.size-medium button {
    padding: 0.4375rem 2.2rem 0.4375rem 1.125rem !important;

    nb-icon {
      right: 0.41rem !important;
    }
  }

  nb-select.appearance-outline .select-button {
    background: white;
  }

  [nbInput] {
    background: white;
  }

  ng2-smart-table {
    table {
      tbody {
        /* Alternating row colour */
        tr {
          background-color: white !important;
        }

        tr:hover {
          background-color: white !important;
        }

        td {
          border-left: 0 solid;
          border-right: 0 solid;
        }
      }

      /* headers */
      tr.ng2-smart-titles th {
        background: #f7f7f7;
        border-left: 0.19rem solid #e8e8e8;
        line-height: 0.12rem;
        border-right: 0 solid;
        border-top: 0 solid;
        border-bottom: 0 solid;

        a {
          color: #a6a6a6;
        }
      }
    }
  }
}

