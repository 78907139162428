@mixin ngx-layout() {
  @include media-breakpoint-down(is) {
    .row {
      margin-left: -0.62rem;
      margin-right: -0.62rem;
    }
  }

  .tabs-menu {
    background: #b7daeb;
    height: 3.5rem;
    margin: auto;
    width:100%;
  }

  nb-layout-header nav {
    height: 3.5rem;
  }

  nb-layout .layout .layout-container nb-sidebar.fixed {
    top: 3.5rem;
  }

  nb-layout-header.fixed ~ .layout-container {
    padding-top: 3.5rem;
  }

  nb-layout .layout .layout-container nb-sidebar .main-container-fixed {
    top: 3.5rem;
  }

  nb-sidebar, nb-sidebar > div.main-container {
    transition: width 0.25s;
  }

  nb-layout .menu-sidebar.compacted nb-menu .menu-items > .menu-item a {
    width:100%;
    padding:0.4rem;
    padding-left:0.5rem;
    margin:0.2rem;
  }

  nb-layout .layout nb-layout-header nav {
    overflow-y: hidden;
    scrollbar-color: #FFFFFF;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: nb-theme(color-info-300) !important;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: nb-theme(color-primary-active) !important;
  }
}
