@import '@ng-select/ng-select/themes/default.theme.css';

.ng-select .ng-select-container {
  min-height: 2rem;
}

.ng-select.ng-select-single .ng-select-container {
  height: auto;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-top: 3px;
  padding-left: 7px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value,
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  margin-bottom: 3px;
}

.ng-dropdown-panel {
  width: auto !important;
  min-width: 100%;
}

.ng-dropdown-panel {
  @import 'scroll-bars';
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  display: flex !important;
  align-items: center;

  input {
    margin-right: 5px;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  padding: 2px 10px;
}
